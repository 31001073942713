.profilePhoto-enter {
	opacity: 0.01;
	transform: scale(1.1);
}

.profilePhoto-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: all 200ms;
}

.profilePhoto-exit {
	opacity: 1;
	transform: scale(1);
}

.profilePhoto-exit-active {
	opacity: 0.01;
	transform: scale(1.1);
	transition: all 200ms;
}
